import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// COMPONENTS
import Header from './Components/Header/Header';
import Loader from './Components/Loader/Loader';
import Footer from './Components/Footer/Footer';
import './App.scss';

// ROUTES
const Home = React.lazy(() => import('./Containers/Home/Home'));
const AboutUs = React.lazy(() => import('./Containers/AboutUs/AboutUs'));
const Product = React.lazy(() => import('./Containers/Product/Product'));
const Clients = React.lazy(() => import('./Containers/Clients/Clients'));
const ContactUs = React.lazy(() => import('./Containers/ContactUs/ContactUs'));
const PrivacyPolicy = React.lazy(() => import('./Containers/PrivacyPolicy/PrivacyPolicy'));
const PageNotFound = React.lazy(() => import('./Components/PageNotFound/PageNotFound'));

function App() {
  return (
    <div className="App">
      <React.Suspense fallback={<Loader />}>
        <Router>
          <Header />
          <Switch>
            <Route exact path='/' render={() => <Home />} />
            <Route exact path='/aboutus' render={() => <AboutUs />} />
            <Route exact path='/product' render={() => <Product />} />
            <Route exact path='/clients' render={() => <Clients />} />
            <Route exact path='/contactus' render={() => <ContactUs />} />
            <Route exact path='/privacypolicy' render={() => <PrivacyPolicy />} />
            <Route path='/*' render={() => <PageNotFound />} />
          </Switch> 
         <Footer />
        </Router>
      </React.Suspense> 
     
    </div>
  );
}

export default App;
